import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import api from '../middleware/api';
import thunk from 'redux-thunk';

import { UPDATE_PS_ENDPOINT_SUCCESS, FETCH_PS_ENDPOINTS_SUCCESS } from '../actions';

import displayReducer from '../reducers/display';
import {
    bookmarksReducer, notificationsReducer, recentOrdersReducer, remindersReducer,
    notificationCountReducer,
} from '../reducers/header';
import { window } from '../global';

var initialState = typeof(initialState) === 'undefined' ? window.initialState : initialState;

const tenantDropdownReducer = (state, action) => {
    let tenants;
    let new_state;

    switch (action.type) {
        case FETCH_PS_ENDPOINTS_SUCCESS:
            tenants = action.payload.tenants;
            new_state = Object.values(tenants).map(t => t.tenant_id);

            return new_state;
    }

    return state;
};

const dropdownReducer = (state = initialState.dropdowns, action) => {
    const new_state = Object.assign({}, state, {
        tenants: tenantDropdownReducer(state.tenants, action)
    });
    return new_state;
};

const identityReducer = (state = initialState.identity, action) => {
    return state;
};

const tenantEndpointsReducer = (state, action) => {
    let endpoint_id;
    let data;
    let new_state;
    let tenant_endpoints;

    switch (action.type) {
        case UPDATE_PS_ENDPOINT_SUCCESS:
            endpoint_id = action.payload.endpoint_id;
            data = action.payload.endpoint;
            new_state = Object.assign({}, state);
            for(let key in new_state) {
                if (new_state.hasOwnProperty(key) && new_state[key].endpoint_id === endpoint_id) {
                    if(data.commonsku_username) {
                        new_state[key].commonsku_username = data.commonsku_username;
                    }
                    if(data.commonsku_password) {
                        new_state[key].commonsku_password = data.commonsku_password;
                    }
                    if(data.promo_api_username) {
                        new_state[key].promo_api_username = data.promo_api_username;
                    }
                    if(data.promo_api_password) {
                        new_state[key].promo_api_password = data.promo_api_password;
                    }
                    new_state[key].note = data.note;
                    new_state[key].active = data.active == 1 ? 'yes' : 'no';
                    new_state[key].problem = data.problem == 1 ? 'yes' : 'no';
                    new_state[key].error = data.error;
                    new_state[key].weekly_count = data.weekly_count;
                }
            }

            return new_state;
        case FETCH_PS_ENDPOINTS_SUCCESS:
            tenant_endpoints = action.payload.tenant_endpoints;

            return tenant_endpoints;
    }

    return state;
};

const tenantReducer = (state, action) => {
    let tenants;

    switch (action.type) {
        case FETCH_PS_ENDPOINTS_SUCCESS:
            tenants = action.payload.tenants;

            return tenants;
    }

    return state;
};

const entitiesReducer = (state = initialState.entities, action) => {
    const new_state = Object.assign({}, state, {
        tenant_endpoints: tenantEndpointsReducer(state.tenant_endpoints, action),
        tenants: tenantReducer(state.tenants, action),
        bookmarks: bookmarksReducer(state.bookmarks, action),
        recent_orders: recentOrdersReducer(state.recent_orders, action),
        reminders: remindersReducer(state.reminders, action),
        notifications: notificationsReducer(state.notifications, action),
        notification_count: notificationCountReducer(state.notification_count, action),
    });
    return new_state;
};

export const reducers = {
    display: displayReducer,
    dropdowns: dropdownReducer,
    entities: entitiesReducer,
    identity: identityReducer
};

const rootReducer = combineReducers(reducers);

export default function configurePSReportStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}
