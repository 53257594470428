import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ShowPopup, Button, Theme } from '@commonsku/styles';
import LabelledSelect from './LabelledSelect';
import PSCredentialUploadPopup from './PSCredentialUploadPopup';

import { createFetchPSEndpoints } from '../actions';
import { createPSTestPopup, createEditPSEndpointPopup } from '../actions/popup';

import { getTenantOptions, getSupplierOptions } from '../selectors/dropdowns';
import { getCompanies } from '../promostandards';

const results_per_page = 30;
const min_page = 1;

class PSReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: 'overview',
            tenant_id: '',
            supplier_id: '',
            endpoint_code: '',
            has_credentials: '',
            live: '',
            active: '',
            problem: '',
            feature_name: '',
            requested: '',
        };
        this.state = {
            ...this.state,
            ...this.getStateFromProps(props, this.state),
        };

        _.bindAll(this, ['handleChangeFilter', 'handleClickTest', 'handleClickPage', 'handleReset', 'handleClickOverviewRow']);
    }

    componentDidMount() {
        this.syncCurrentPageEndpoints(this.state);
    }

    getStateFromProps(props, state) {
        const max_page = Math.ceil(Object.values(props.tenant_endpoints).length / results_per_page);
        const current_page = 1;
        const tenant_endpoints = props.tenant_endpoints;
        state = {
            ...(state || this.state),
            tenant_endpoints,
            current_page,
            max_page,
            total_pages: _.range(1, max_page),
        };
        state = {
            ...state,
            ...this.filterEndpoints(state),
        };

        return {
            ...state,
            current_page_endpoints: this.getPaginatedEndpoints(state),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let state = this.state;
        if (prevProps != this.props) {
            state = {
                ...state,
                ...this.getStateFromProps(this.props),
            };
        }
        let { tenant_endpoints, current_page } = state;
        if (current_page != prevState.current_page || tenant_endpoints != prevState.tenant_endpoints) {
            this.syncCurrentPageEndpoints(state);
        }
    }

    async syncCurrentPageEndpoints(state) {
        this.setState({ loading: true });
        const { current_page, tenant_endpoints } = state;
        const endpoints = this.getPaginatedEndpoints({ current_page, tenant_endpoints });
        try {
            const data = await getCompanies({
                fields: {name: null, code: null, endpoints: null},
                code__in: _.uniq(_.map(endpoints, 'ps_company_code')),
            });
            const results = {};
            _.map(data, (company) => {
                _.map(company.endpoints, (endpoint) => {
                    _.set(
                        results, [company.code, endpoint.code, endpoint.version],
                        endpoint.weekly_count + (
                            _.get(results, [company.code, endpoint.code, endpoint.version]) || 0
                        )
                    );
                });
            });
            this.setState({
                ...state,
                loading: false,
                current_page_endpoints: _.map(endpoints, (endpoint) => {
                    const count = _.sum(_.values(_.get(
                        results,
                        [endpoint.ps_company_code, endpoint.endpoint_code]
                    ))) || 0;
                    return {
                        ...endpoint,
                        weekly_count: (parseFloat(endpoint.weekly_count) || 0) + count,
                    };
                }),
            });
        } catch (e) {
            this.setState({ ...state, loading: false });
        }
    }

    getPaginatedEndpoints({ current_page, tenant_endpoints }) {
        const index = current_page - 1;
        return Object.values(tenant_endpoints).slice(index * results_per_page, index * results_per_page + 30);
    }

    handleReset() {
        this.setState({
            tenant_id: '',
            supplier_id: '',
            endpoint_code: '',
            has_credentials: '',
            live: '',
            active: '',
            problem: '',
            feature_name: '',
            requested: '',
            ...this.getStateFromProps(this.props),
        });
    }

    calculatePageLimits(page, max_page) {
        const previous = page - 4 < min_page ? min_page : page - 4;
        let next;
        if(+previous === 1 && +page !== 5) {
            next = page + 8 > max_page ? max_page : page + 8;
        }else{
            next = page + 4 > max_page ? max_page : page + 4;
        }

        return [previous, next];
    }

    calculatePageList(page, max_page) {
        return _.range(...this.calculatePageLimits(page, max_page));
    }

    handleClickPage(current_page) {
        const state = {...this.state, current_page};
        this.setState({
            ...state,
            current_page_endpoints: this.getPaginatedEndpoints(state),
        });
    }

    handleChangeFilter(e, field) {
        let state = {
            ...this.state,
            [field]: e, current_page: min_page
        };
        if(field === 'tab') {
            if (e === 'overview') {
                state = {
                    ...state,
                    tenant: '', endpoint_code: '', live: '', active: '', problem: ''
                };
            } else if (e === 'supplier') {
                state = {
                    ...state,
                    tenant: '', feature_name: '', has_credentials: ''
                };
            } else {
                state = {
                    ...state,
                    feature_name: '',
                };
            }
            this.setState({
                ...state,
                loading: true,
            });
            this.props.onCreateFetchPSEndpoints(e, state.supplier_id).then(() => {
                this.setState({ loading: false });
            });
        }else{
            this.setState({
                ...state,
                ...this.filterEndpoints({
                    ...state,
                    tenant_endpoints: this.props.tenant_endpoints
                }),
            });
        }
    }

    filterEndpoints({
        tenant_endpoints, tenant_id, supplier_id, endpoint_code, tab, live, active, problem, feature_name,
        requested, has_credentials, max_page, current_page
    }) {
        const services = {
            'INV': 'inventory_service',
            'ODRSTAT': 'order_status_service',
            'OSN': 'shipment_service',
            'PO': 'purchase_order_service',
        };
        tenant_endpoints = Object.values(tenant_endpoints)
            .filter(te => tenant_id === '' || te.tenant_id === tenant_id)
            .filter(te => supplier_id !== '' && supplier_id !== 'none'
                ? te.supplier_id === supplier_id
                    : supplier_id === 'none'
                        ? !te.promo_api_name
                            : te.supplier_id === te.supplier_id
            )
            .filter(te => endpoint_code === '' ||
                (tab !== 'overview' && te.endpoint_code === endpoint_code) ||
                (tab === 'overview' && te[services[endpoint_code]] > 0))
            .filter(te => live === '' || te.live === live)
            .filter(te => active === '' || te.active === active)
            .filter(te => problem === '' || te.problem === problem)
            .filter(te => feature_name === '' || te.feature_name === feature_name)
            .filter(te => requested === 'yes' ? parseInt(te.weekly_count) > 0  : requested === 'no' ? parseInt(te.weekly_count) === 0 : te.weekly_count === te.weekly_count);

        if (has_credentials !== '') {
            if (tab === 'overview') {
                if (has_credentials === 'yes') {
                    tenant_endpoints = tenant_endpoints.filter(te =>
                        (te.commonsku_username && te.commonsku_username !== '')
                    );
                } else {
                    tenant_endpoints = tenant_endpoints.filter(te =>
                        (typeof te.commonsku_username === 'undefined' || te.commonsku_username === '' || !te.commonsku_username)
                    );
                }
            }else if (tab === 'supplier') {
                if (has_credentials === 'yes') {
                    tenant_endpoints = tenant_endpoints.filter(te =>
                        (['INV', 'CRE'].indexOf(te.endpoint_code) !== -1 && te.commonsku_username && te.commonsku_username !== '')
                    );
                } else {
                    tenant_endpoints = tenant_endpoints.filter(te =>
                        (['INV', 'CRE'].indexOf(te.endpoint_code) !== -1 && (typeof te.commonsku_username === 'undefined' || te.commonsku_username === '' || !te.commonsku_username))
                    );
                }
            }else{
                if (has_credentials === 'yes') {
                    tenant_endpoints = tenant_endpoints.filter(te =>
                        (['INV', 'CRE'].indexOf(te.endpoint_code) !== -1 && te.commonsku_username && te.commonsku_username !== '') ||
                        (['ODRSTAT', 'OSN'].indexOf(te.endpoint_code) !== -1 && te.promo_api_username && te.promo_api_username !== '')
                    );
                } else {
                    tenant_endpoints = tenant_endpoints.filter(te =>
                        (['INV', 'CRE'].indexOf(te.endpoint_code) !== -1 && (typeof te.commonsku_username === 'undefined' || te.commonsku_username === '')) ||
                        (['ODRSTAT', 'OSN'].indexOf(te.endpoint_code) !== -1 && (typeof te.promo_api_username === 'undefined' || te.promo_api_username === '' || !te.promo_api_username))
                    );
                }
            }
        }

        if(tab === 'overview') {
            tenant_endpoints = tenant_endpoints.reduce((o, te) => { o[te.division_id] = te; return o; }, {});
        }else if(tab === 'supplier') {
            tenant_endpoints = tenant_endpoints.reduce((o, te) => { o[te.endpoint_id] = te; return o; }, {});
        }else {
            tenant_endpoints = tenant_endpoints.reduce((o, te) => { o[te.tenant_id + te.endpoint_id] = te; return o; }, {});
        }

        max_page = Math.ceil(Object.values(tenant_endpoints).length / results_per_page);
        const show_pages = this.calculatePageList(current_page, max_page);
        return {
            tenant_endpoints,
            max_page,
            show_pages,
        };
    }

    handleClickOverviewRow(te) {
        this.setState({ supplier_id: te.supplier_id }, function() {
            this.handleChangeFilter('supplier', 'tab');
        });
    }

    handleClickTest(te) {
        if (this.state.tab === 'supplier' && ['ODRSTAT', 'OSN'].indexOf(te.endpoint_code) !== -1) {
            this.setState({ supplier_id: te.supplier_id, endpoint_code: te.endpoint_code, has_credentials: 'yes' }, function() {
                this.handleChangeFilter('tenant-supplier', 'tab');
            });
        }else {
            this.props.onCreatePSTestPopup(te);
        }
    }

    renderLoading() {
        return (
            <div className='row popup-content column'>
                <div className='small-12 columns'>
                    <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
                </div>
            </div>
        );
    }

    renderHeader() {
        const { header_style } = styles;

        if(this.state.tab === 'overview') {
            return (
                <tr className="cursor-default">
                    <td width="10%" style={header_style} className="table-cell-sticky">Supplier</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">Endpoint Owner</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">Feature</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">cs Cred.</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">Tenant Cred. #</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">has INV</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">has ODRSTAT</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">has OSN</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">has PO</td>
                    {/* <td width="8%" style={header_style} className="table-cell-sticky">CRED</td> */}
                    <td width="10%" style={header_style} className="table-cell-sticky">Credentials</td>
                </tr>
            );
        }else if(this.state.tab === 'supplier') {
            return (
                <tr className="cursor-default">
                    <td width="10%" style={header_style} className="table-cell-sticky">Supplier</td>
                    {['INV', 'CRE'].indexOf(this.state.endpoint_code) !== -1 && <td width="5%" style={header_style} className="table-cell-sticky">cs Cred.</td>}
                    {['ODRSTAT', 'OSN'].indexOf(this.state.endpoint_code) !== -1 && <td width="10%" style={header_style} className="table-cell-sticky">Tenant Cred. #</td>}
                    <td width="15%" style={header_style} className="table-cell-sticky">Endpoint Url</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Type</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Version</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Active</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Success</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">Note</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">Error</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Weekly Count</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Test</td>
                </tr>
            );
        }else{
            return (
                <tr className="cursor-default">
                    <td width="10%" style={header_style} className="table-cell-sticky">Tenant</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">Supplier</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Tenant Cred.</td>
                    <td width="25%" style={header_style} className="table-cell-sticky">Endpoint Url</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Type</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Version</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Active</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Success</td>
                    <td width="10%" style={header_style} className="table-cell-sticky">Error</td>
                    <td width="5%" style={header_style} className="table-cell-sticky">Test</td>
                </tr>
            );
        }
    }

    renderIcon(value, field) {
        if(field === 'problem') {
            if (value === 'yes') {
                return <img src="/images/unapproved.png" />;
            }
            if (value === 'no') {
                return <img src="/images/approved.png" />;
            }
        }else{
            if (value === 'yes') {
                return <img src="/images/approved.png" />;
            }
            if (value === 'no') {
                return <img src="/images/unapproved.png" />;
            }
        }

        return value;
    }

    renderRow(te) {
        const { test_button_style } = styles;

        const has_commonsku_credentials = te.commonsku_username && te.commonsku_username !== '' ? 'yes' : 'no';
        const has_tenant_credentials = te.promo_api_username && te.promo_api_username !== '' ? 'yes' : 'no';

        // skip testing credential service for now
        let showTestButton = false;
        if(this.state.tab !== 'overview') {
            showTestButton = (['INV'].indexOf(te.endpoint_code) !== -1 && has_commonsku_credentials === 'yes')
                || (['ODRSTAT', 'OSN'].indexOf(te.endpoint_code) !== -1 && this.state.tab === 'supplier')
                || (['ODRSTAT', 'OSN'].indexOf(te.endpoint_code) !== -1 && has_tenant_credentials === 'yes' && this.state.tab === 'tenant-supplier');
        }

        if(this.state.tab === 'overview') {
            return (
                <tr key={te.division_id} onClick={e => { e.preventDefault(); this.handleClickOverviewRow(te); }}>
                    <td><b><a href={`/vendor.php?id=${te.supplier_id}&division_id=${te.division_id}`}
                        target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}
                    >{te.supplier_name}</a></b></td>
                    <td><b><a href={`/vendor.php?id=${te.supplier_id}&division_id=${te.division_id}`}
                        target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}
                    >{te.division_name}</a></b></td>
                    <td>{te.feature_name}</td>
                    <td>{this.renderIcon(has_commonsku_credentials)}</td>
                    <td>{te.tenant_credentials_count} / {this.props.full_tenants_count}</td>
                    <td>{this.renderIcon(te.inventory_service > 0 ? 'yes' : 'no')}</td>
                    <td>{this.renderIcon(te.order_status_service > 0 ? 'yes' : 'no')}</td>
                    <td>{this.renderIcon(te.shipment_service > 0 ? 'yes' : 'no')}</td>
                    <td>{this.renderIcon(te.purchase_order_service > 0 ? 'yes' : 'no')}</td>
                    {/* <td>{te.credential_service}</td> */}
                    <td onClick={(e) => e.stopPropagation()}>
                        {te.promo_api_name && <Theme>
                            <ShowPopup
                                popup={(popupProps) => {
                                    return <PSCredentialUploadPopup {...popupProps} ps_company_code={te.ps_company_code} promo_api_name={te.promo_api_name}/>;
                                }}
                                render={({ onClick }) => {
                                    return <Button type="button" size="small" onClick={onClick}>Upload</Button>;
                                }}
                            />
                        </Theme>}
                    </td>
                </tr>
            );
        }else if(this.state.tab === 'supplier') {
            return (
                <tr key={te.endpoint_id} onClick={e => { e.preventDefault(); this.props.onCreateEditPSEndpointPopup(te); }}>
                    <td>
                        <b><a href={`/vendor.php?id=${te.supplier_id}&division_id=${te.division_id}`}
                            target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}
                        >{te.division_name}</a></b>
                        <br />
                        {te.supplier_name}
                    </td>
                    {['INV', 'CRE'].indexOf(this.state.endpoint_code) !== -1 && <td>{this.renderIcon(has_commonsku_credentials)}</td>}
                    {['ODRSTAT', 'OSN'].indexOf(this.state.endpoint_code) !== -1 && <td>{te.tenant_credentials_count} / {this.props.full_tenants_count}</td>}
                    <td style={{ wordBreak: 'break-all' }}><b>
                        <a href={te.endpoint_url} target="_blank" rel="noopener noreferrer"
                            onClick={e => e.stopPropagation()}
                        >{te.endpoint_url}</a></b>
                    </td>
                    <td>{te.endpoint_code}</td>
                    <td>{te.endpoint_version}</td>
                    <td>{this.renderIcon(te.active)}</td>
                    <td>{this.renderIcon(te.problem, 'problem')}</td>
                    <td style={{ wordBreak: 'break-all' }}>{te.note}</td>
                    <td style={{ wordBreak: 'break-all' }}>{te.error}</td>
                    <td>{te.weekly_count}</td>
                    <td>{showTestButton ? <button className="small button" style={test_button_style} onClick={e => { e.preventDefault(); e.stopPropagation(); this.handleClickTest(te); }}>Test</button> : 'Missing Credentials'}</td>
                </tr>
            );
        }else{
            return (
                <tr key={te.tenant_id + te.endpoint_id} onClick={e => { e.preventDefault(); this.props.onCreateEditPSEndpointPopup(te); }}>
                    <td><b>{te.tenant_name}</b></td>
                    <td>
                        <b><a href={`/vendor.php?id=${te.supplier_id}&division_id=${te.division_id}`}
                            target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}
                        >{te.division_name}</a></b>
                        <br />
                        {te.supplier_name}
                    </td>
                    <td>{['INV', 'CRE'].indexOf(te.endpoint_code) !== -1 ? 'n/a' : this.renderIcon(has_tenant_credentials)}</td>
                    <td style={{ wordBreak: 'break-all' }}><b><a
                        href={te.endpoint_url} target="_blank" rel="noopener noreferrer"
                        onClick={e => e.stopPropagation()}
                    >{te.endpoint_url}</a></b></td>
                    <td>{te.endpoint_code}</td>
                    <td>{te.endpoint_version}</td>
                    <td>{this.renderIcon(te.active)}</td>
                    <td>{this.renderIcon(te.problem, 'problem')}</td>
                    <td style={{ wordBreak: 'break-all' }}>{te.error}</td>
                    <td>{showTestButton ? <button className="small button" style={test_button_style} onClick={e => { e.preventDefault(); e.stopPropagation(); this.handleClickTest(te); }}>Test</button> : 'Missing Credentials'}</td>
                </tr>
            );
        }
    }

    renderNoRow() {
        return (
            <tr>
                <td colSpan="12">There are no endpoints available</td>
            </tr>
        );
    }

    renderPageList() {
        const { page_list_container, page_button_style, page_button_active_style, page_between_style } = styles;

        return (
            <div key="pages" className="row" style={page_list_container}>
                <div className="large-5 large-centered columns">
                    {this.state.current_page > min_page ?
                        <a style={page_button_style} key="prev" onClick={e => { e.preventDefault(); this.handleClickPage(this.state.current_page - 1); }}>PREV</a>
                        : null}
                    {this.state.show_pages.indexOf(min_page) === -1 ?
                        <a key={min_page}
                            style={this.state.current_page === min_page ? page_button_active_style : page_button_style}
                            onClick={e => { e.preventDefault(); this.handleClickPage(min_page); }}>
                            {min_page}
                        </a>
                        : null}
                    {this.state.show_pages.indexOf(min_page) === -1 && this.state.show_pages.indexOf(min_page + 1) === -1 && Object.values(this.state.tenant_endpoints).length > results_per_page ?
                        <span style={page_between_style}>...</span>
                        : null}
                    {this.state.show_pages.map(p =>
                        <a style={this.state.current_page === p ? page_button_active_style : page_button_style} key={p} onClick={e => { e.preventDefault(); this.handleClickPage(p); }}>{p}</a>
                    )}
                    {this.state.show_pages.indexOf(this.state.max_page) === -1 && this.state.show_pages.indexOf(this.state.max_page - 1) === -1 && Object.values(this.state.tenant_endpoints).length > results_per_page ?
                        <span style={page_between_style}>...</span>
                        : null}
                    {this.state.show_pages.indexOf(this.state.max_page) === -1 && this.state.max_page !== 1 ?
                        <a key={this.state.max_page}
                            style={this.state.current_page === this.state.max_page ? page_button_active_style : page_button_style}
                            onClick={e => { e.preventDefault(); this.handleClickPage(this.state.max_page); }}>
                            {this.state.max_page}
                        </a>
                        : null}
                    {this.state.current_page < this.state.max_page ?
                        <a style={page_button_style} key="next" onClick={e => { e.preventDefault(); this.handleClickPage(this.state.current_page + 1); }}>NEXT</a>
                        : null}
                </div>
            </div>
        );
    }

    render() {
        const { summary_style, reset_style } = styles;
        const { tenants, suppliers } = this.props;
        const { current_page_endpoints } = this.state;

        const tenant_options = [{ key: '', value: 'All' }].concat(tenants);
        const supplier_options = [{ key: '', value: 'All' }, { key: 'none', value: 'None' }].concat(suppliers);
        const type_options = [
            { key: '', value: 'All' },
            { key: 'INV', value: 'Inventory' },
            { key: 'ODRSTAT', value: 'Order Status' },
            { key: 'OSN', value: 'Order Shipment Notification' },
            { key: 'PO', value: 'Purchase Order' },
            /* { key: 'CRE', value: 'Credential' } */
        ];
        const credential_options = [
            { key: '', value: 'All' },
            { key: 'yes', value: 'Has Credentials' },
            { key: 'no', value: 'No Credentials' }
        ];
        const active_options = [
            { key: '', value: 'All' },
            { key: 'yes', value: 'Active' },
            { key: 'no', value: 'Inactive' }
        ];
        const problem_options = [
            { key: '', value: 'All' },
            { key: 'no', value: 'Last Call Successful / Not Tested' },
            { key: 'yes', value: 'Last Call Not Successful' }
        ];
        const feature_options = [
            { key: '', value: 'All' },
            { key: 'COMMUNITYBASIC', value: 'Community' },
            { key: 'COMMUNITYPLUS', value: 'Community+' },
            { key: 'CONNECTEDBASIC', value: 'Connected' },
            { key: 'CONNECTEDPLUS', value: 'Connected+' },
            { key: 'CONNECTED', value: 'Connected (Old)' },
            { key: 'INSIGHTS', value: 'Insights' },
            { key: 'COLLABORATE', value: 'Collaborate' },
            { key: 'DORMANT', value: 'Dormant' }
        ];
        const requested_options = [
            { key: '', value: 'Any' },
            { key: 'yes', value: 'Has been requested this week' },
            { key: 'no', value: 'Not been requested this week' }
        ];

        const select_class = this.state.tab === 'overview' ? 'small-12 medium-3 columns' : 'small-12 medium-4 columns';

        return (
            <div className="main-content">
                <div className="row full-width">
                    <ul className="tabs" data-tabs style={{ background: '#EDF2F5', border: 'none' }}>
                        <li className={'tabs-title' + (this.state.tab === 'overview' ? ' is-active' : '')}>
                            <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('overview', 'tab'); }} aria-selected={this.state.tab === 'overview'}>Overview</a>
                        </li>
                        <li className={'tabs-title' + (this.state.tab === 'supplier' ? ' is-active' : '')}>
                            <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('supplier', 'tab'); }} aria-selected={this.state.tab === 'supplier'}>Generic</a>
                        </li>
                        <li className={'tabs-title' + (this.state.tab === 'tenant-supplier' ? ' is-active' : '')} style={this.state.tab !== 'tenant-supplier' ? { display: 'none' } : null}>
                            <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('tenant-supplier', 'tab'); }} aria-selected={this.state.tab === 'tenant-supplier'}>Tenants Specific</a>
                        </li>
                        <button type="button" className="small button" onClick={e => { e.preventDefault(); this.handleReset(); }} style={reset_style}>Reset</button>
                        <li style={summary_style}>
                            <span><b># of Suppliers on Promostandards: </b>{suppliers.length}&nbsp;&nbsp;</span>
                            <span>&nbsp;&nbsp;<b># of Rows: </b>{Object.values(this.state.tenant_endpoints).length}</span>
                        </li>
                    </ul>
                </div>
                <div className="row collapse" style={{ maxWidth: 'initial', marginTop: '10px', fontSize: '14px' }}>
                    {this.state.tab === 'tenant-supplier' ?
                        <LabelledSelect className={select_class} placeholder="Tenants" value={this.state.tenant_id} options={tenant_options} onChange={e => this.handleChangeFilter(e, 'tenant_id')} disabled={this.state.loading} />
                    : null}
                    <LabelledSelect className={select_class} placeholder="Suppliers" value={this.state.supplier_id} options={supplier_options} onChange={e => this.handleChangeFilter(e, 'supplier_id')} disabled={this.state.loading} />
                    <LabelledSelect className={select_class} placeholder="Type" value={this.state.endpoint_code} options={type_options} onChange={e => this.handleChangeFilter(e, 'endpoint_code')} disabled={this.state.loading} />
                    {this.state.tab === 'overview' || (this.state.tab === 'supplier' && ['INV', 'CRE'].indexOf(this.state.endpoint_code) !== -1) || this.state.tab === 'tenant-supplier' ?
                        <LabelledSelect
                            className={select_class}
                            placeholder={this.state.tab === 'overview' || this.state.tab === 'supplier' ? "commonsku Credentials" : "Tenant Credentials"}
                            value={this.state.has_credentials}
                            options={credential_options}
                            onChange={e => this.handleChangeFilter(e, 'has_credentials')}
                            disabled={this.state.loading}
                        />
                    : null}
                    {this.state.tab === 'supplier' || this.state.tab === 'tenant-supplier' ?
                        <LabelledSelect className={select_class} placeholder="Active" value={this.state.active} options={active_options} onChange={e => this.handleChangeFilter(e, 'active')} disabled={this.state.loading} />
                    : null}
                    {this.state.tab === 'supplier' || this.state.tab === 'tenant-supplier' ?
                        <LabelledSelect className={select_class + ' end'} placeholder="Success" value={this.state.problem} options={problem_options} onChange={e => this.handleChangeFilter(e, 'problem')} disabled={this.state.loading} />
                    : null}
                    {this.state.tab === 'overview' || this.state.tab === 'supplier' ?
                        <LabelledSelect className={select_class + ' end'} placeholder="Feature" value={this.state.feature_name} options={feature_options} onChange={e => this.handleChangeFilter(e, 'feature_name')} disabled={this.state.loading} />
                    : null}
                    {this.state.tab === 'supplier' ?
                        <LabelledSelect className={select_class + ' end'} placeholder="Requested" value={this.state.requested} options={requested_options} onChange={e => this.handleChangeFilter(e, 'requested')} disabled={this.state.loading} />
                    : null}
                </div>
                {this.state.loading ?
                    this.renderLoading()
                    :
                    [<table key="table" style={{ marginBottom: 0, tableLayout: 'fixed' }}>
                        <thead style={{ fontSize: '14px' }}>
                            {this.renderHeader()}
                        </thead>
                        <tbody style={{ fontSize: '12px' }}>
                            {Object.values(this.state.tenant_endpoints).length > 0 ? current_page_endpoints.map(te =>
                                this.renderRow(te)
                            ) :
                                this.renderNoRow()
                            }
                        </tbody>
                    </table>,
                    Object.values(this.state.tenant_endpoints).length > 0 ? this.renderPageList() : null]
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tenant_endpoints: state.entities.tenant_endpoints,
        tenants: getTenantOptions(state),
        suppliers: getSupplierOptions(state),
        full_tenants_count: state.entities.full_tenants_count
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreatePSTestPopup: (tenant_endpoint) => {
            dispatch(createPSTestPopup(tenant_endpoint));
        },
        onCreateEditPSEndpointPopup: (tenant_endpoint) => {
            dispatch(createEditPSEndpointPopup(tenant_endpoint));
        },
        onCreateFetchPSEndpoints: (tab, supplier_id) => {
            return dispatch(createFetchPSEndpoints(tab, supplier_id));
        },
    };
};

const styles = {
    header_style: {
        top: '68px',
        background: '#EDF2F5'
    },
    page_list_container: {
        marginBottom: '150px'
    },
    page_button_style: {
        background: '#edf2f4',
        border: '1px solid #c6dde4',
        borderRadius: '3px',
        color: '#5ba0b4',
        padding: '5px 4px 4px 4px',
        fontSize: '13px',
        cursor: 'pointer',
        float: 'left',
        minWidth: '27px',
        textAlign: 'center'
    },
    page_button_active_style: {
        background: '#aaa',
        border: '1px solid #c6dde4',
        borderRadius: '3px',
        color: '#5ba0b4',
        padding: '5px 4px 4px 4px',
        fontSize: '13px',
        cursor: 'pointer',
        float: 'left',
        minWidth: '27px',
        textAlign: 'center'
    },
    page_between_style: {
        float: 'left',
        margin: '0 10px'
    },
    summary_style: {
        float: 'right',
        margin: '10px',
        backgroundColor: '#DFF1FB',
        padding: '5px 20px'
    },
    reset_style: {
        margin: '12px 10px 0px 0px',
        float: 'right'
    },
    test_button_style: {
        margin: 0
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PSReport);
