import { trim, mapValues, findIndex, filter, map, zip, chunk, isEmpty, uniqBy, flatten } from 'lodash';
import React, { useState } from 'react';

import { H3, Popup, Dropzoned, Button } from '@commonsku/styles';

import { checkCredential } from '../promostandards';
import { asyncMap, oauth } from '../utils';
import { csvToJson } from '../helpers/file';

const ToggleDetails = ({ children }) => {
  const [show, setShow] = useState(false);

  return <>
    <Button type="button" size="small" onClick={() => {
      setShow(!show);
    }}>{show ? 'Hide' : 'Show'}</Button>
    {show && children}
  </>;
};

const PSCredentialUploadPopup = ({ ps_company_code, promo_api_name, ...props }) => {
  const initialState = { csvErrors: [], rows: [], current: '', success: [], missing: [], errors: [], };
  const [state, setState] = useState(initialState);

  return <Popup
    {...props}
    style={{ padding: '5px 25px' }}
    title={'Upload PS Credentials'}
  >
    <div>
      <div>Only *.csv files will be accepted.</div>
      <div>CSV file must include header distributor_name, promostandards_id, promostandards_password</div>
      <div>promostandards_account is optional</div>
    </div>
    <Dropzoned
      showDroppedFiles={true}
      onDrop={async (acceptedFiles) => {
        let csvErrors = [];
        const rows = filter(flatten(await Promise.all(map(acceptedFiles, async (file) => {
          const { data, errors } = await csvToJson(file);
          csvErrors.push(errors);
          return map(data, (row) => {
            return mapValues(row, (v) => {
              return trim(v);
            });
          });
        }))));
        setState({ ...initialState, rows, csvErrors: flatten(csvErrors) });
      }}
    />
    {!isEmpty(state.csvErrors) && <div style={{ marginTop: 20 }}>
      <H3>{state.csvErrors.length} Errors</H3>
      <ToggleDetails>
        <pre style={{ color: 'red' }}>
          {JSON.stringify(state.csvErrors, undefined, 2)}
        </pre>
      </ToggleDetails>
    </div>}
    {isEmpty(state.csvErrors) && !isEmpty(state.rows) && <Button type="button" size="small" onClick={async () => {
      let errors = [];
      const credentials = filter(flatten(await Promise.all(map(zip(...chunk(state.rows, 3)), (thread) => {
        return asyncMap(
          filter(thread, (data) => {
            return data && (
              -1 === findIndex([data.promostandards_id, data.promostandards_password, data.distributor_name], isEmpty)
            );
          }),
          async (data) => {
            setState({ ...state, current: data.distributor_name });
            const response = await checkCredential({
              company: ps_company_code,
              username: data.promostandards_id,
              password: data.promostandards_password,
            });
            await new Promise((resolve) => {
              setTimeout(resolve, 300);
            });
            if (response.ErrorMessage) {
              errors.push({
                ...response.ErrorMessage,
                distributor_name: data.distributor_name,
                promostandards_id: data.promostandards_id,
              });
            } else {
              return {
                tenant_name: data.distributor_name,
                promo_api_account: data.promostandards_account,
                promo_api_username: data.promostandards_id,
                promo_api_password: data.promostandards_password,
              };
            }
          }
        );
      }))));

      if (!isEmpty(credentials)) {
        const { json } = await oauth('POST', 'promostandards-credential', {
          promo_api_name,
          credentials: uniqBy(credentials, 'tenant_name'),
        });
        setState({
          ...initialState,
          errors: [...errors, ...json.errors],
          success: [...json.created, ...json.updated],
          missing: json.missing,
        });
      } else {
        setState({ ...initialState, errors, });
      }
    }}>Upload</Button>}
    {state.current && <div>Processing: {state.current}</div>}
    <div style={{ marginTop: 25 }}>
      <H3>{state.success.length} rows uploaded.</H3>
      {!isEmpty(state.success) && <ToggleDetails>
        <pre>{JSON.stringify(map(state.success, 'tenant_name'))}</pre>
      </ToggleDetails>}
    </div>
    {!isEmpty(state.missing) && <div style={{ marginTop: 25 }}>
      <H3>{state.missing.length} distributor_name are not found in commonsku. </H3>
      <ToggleDetails>
        <pre style={{ color: 'red' }}>{JSON.stringify(map(state.missing, 'tenant_name'))}</pre>
      </ToggleDetails>
    </div>}
    {!isEmpty(state.errors) && <div style={{ marginTop: 25 }}>
      <H3 style={{ color: 'red' }}>{state.errors.length} Errors</H3>
      <ToggleDetails>
        <pre style={{ color: 'red' }}>
          {JSON.stringify(state.errors, undefined, 2)}
        </pre>
      </ToggleDetails>
    </div>}
  </Popup>;
};

export default PSCredentialUploadPopup;